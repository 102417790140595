.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#video{
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  visibility: hidden;
  width: auto;
  height: auto;
}

body{background-color: #f2f2f2;}
.home{padding: 20px;}
.home-header{color:rgb(73 73 73); font-weight: bold; }
.home-header h1{font-size:42px; line-height: 1.5em;margin-bottom: 20px;}
.home-header h6{font-weight: bold;}
.list-item{background-color: #FFF;border-radius:20px; color:#000}
.home .list-item:hover{background-color: #FFF}
.list-item .icon{background-color: transparent;}
.maintabs .tab{color:#000; border-radius: 0;}
.maintabs .tab.active{color:var(--tgui--link_color);border-bottom: 2px solid;}
.maintabs .tablink{display: flex; flex: 1 0; text-decoration: none;}

#cameraMain{margin-top: 20px;}